import styled from 'styled-components';

const AvatarWrapper = styled.section`
  background: #263238;
  text-align: center;
  padding: 20px 0px;
  > .fullname,
  > .position {
    text-transform: uppercase;
    color: #fff;
    font-weight: normal;
  }
`;

const AvatarImage = styled.div`
  margin: 0 auto 15px;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  img {
    max-width: 100%;
  }
`;

const Fullname = styled.h2`
  margin: 0px 0px 10px;
`;

const Position = styled.h5`
  font-style: italic;

  margin: 0px;
`;

const Avatar = () => (
  <AvatarWrapper>
    <AvatarImage>
      <img src="/avatar.png" alt="avatar" />
    </AvatarImage>
    <Fullname className="fullname">Nghiep Ngo Trong</Fullname>
    <Position className="position">Frontend Engineer</Position>
  </AvatarWrapper>
);

export default Avatar;
