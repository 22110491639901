import Title from '../components/Title';
import Wrapper from '../components/Wrapper';
import Item from './Item';

const Skill = () => (
  <Wrapper>
    <Title>Favorite Skills</Title>
    <ul>
      <Item width={82}>Javascript / Typescript</Item>
      <Item width={90}>
        React / Next.js / <s>Vue.js</s>
      </Item>
      <Item width={89}>
        NodeJS / GraphQL / <s>Laravel</s> / SQL
      </Item>
      <Item width={85}>Tailwind CSS / Antd / MUI</Item>
      <Item width={70}>Docker, CI/CD</Item>
    </ul>
  </Wrapper>
);

export default Skill;
