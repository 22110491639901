import styled from 'styled-components';

const Wrapper = styled.div`
  height: 9px;
  background: #fff;
`;

const Processed = styled.div<{width: number}>`
  height: 100%;
  background: #263238;
  width: ${props => props.width + '%'};
`;

interface Props {
  width: number;
}

const Processing = ({width}: Props) => (
  <Wrapper>
    <Processed width={width} />
  </Wrapper>
);

export default Processing;
