import styled from 'styled-components';

const TagLink = styled.a.attrs(({href}) => ({
  target: '_blank',
  href,
  rel: 'noopener noreferrer',
}))`
  color: #337ab7;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

type Props = React.PropsWithChildren<{
  href: string;
}>;

const A = ({children, href}: Props) => (
  <TagLink href={href}>{children}</TagLink>
);

export default A;
