import clsx from 'clsx';
import styled from 'styled-components';

const Li = styled.li`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Icon = styled.i`
  margin-right: 10px;
  font-size: 14px;
  width: 11px;
  & + * {
    color: #fff;
    font-size: 0.8em;
  }
`;

const A = styled.a.attrs<{
  isBlank: boolean;
}>(({href, isBlank}) => ({
  href,
  target: isBlank ? '_blank' : '_self',
}))<{
  isBlank: boolean;
}>`
  text-decoration: none;
`;

type Props = React.PropsWithChildren<{
  icon: string;
  href?: string;
  isBlank?: boolean;
}>;

const Item = ({children, icon, href, isBlank = false}: Props) => (
  <Li>
    <Icon className={clsx('fa', 'fa-' + icon)} />
    {href ? (
      <A href={href} isBlank={isBlank}>
        {children}
      </A>
    ) : (
      <span>{children}</span>
    )}
  </Li>
);

export default Item;
