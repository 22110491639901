import styled from 'styled-components';

const Wrapper = styled.section<{bg?: string}>`
  background: ${props => props.bg ?? 'transparent'};
  border-top: 1px solid #e0dbdb;
  padding: 10px 0px 5px;
`;

const Title = styled.h3`
  font-weight: 500;
  margin: 10px 0px 1px;
  & + div > p:first-child {
    font-weight: 500;
    margin-bottom: 2px;
  }
`;

const Description = styled.div`
  font-size: 0.9em;
  p {
    margin: 0;
    padding: 5px 0;
  }
`;

type Props = React.PropsWithChildren<{
  title: string;
  bg?: string;
}>;

const Item = ({children, title, bg}: Props) => (
  <Wrapper bg={bg}>
    <Title>{title}</Title>
    <Description>{children}</Description>
  </Wrapper>
);
export default Item;
