import styled from 'styled-components';

import {GlobalStyle} from './styled';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import './styles.css';

const Main = styled.main`
  width: 800px;
  max-width: 100%;
  margin: 40px auto 20px;
  min-height: 1000px;
  box-shadow: 0 0 5px #000;
  background: #fff;
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
    margin: 0px;
    .sidebar {
      width: 100%;
    }
  }
`;

const App = () => {
  return (
    <Main>
      <Sidebar />
      <MainContent />
      <GlobalStyle />
    </Main>
  );
};

export default App;
