import styled from 'styled-components';

import Icon from '../components/Icon';
import Title from '../components/Title';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const Description = ({children}: React.PropsWithChildren<{}>) => (
  <Wrapper>
    <Icon icon="info-circle" /> <Title>Descriptions</Title>: {children}
  </Wrapper>
);

export default Description;
