import Title from '../components/Title';
import Item from '../components/Item';
import A from '../components/A';

const Education = () => (
  <div>
    <Title icon="graduation-cap">Education</Title>
    <Item title="Ho Chi Minh City University of Transport (2010 - 2015)">
      Major in Information Technology
    </Item>

    <Item title="AWARDS">
      <p>- First prize: Web design in Vietnam Skills 2013 Transport ministry</p>
      <p>
        - Consolation prize: Web design in Vietnam Skills 2014 National level
      </p>
      <p>
        <A href="/awards.jpg">View Awards</A>
      </p>
    </Item>
  </div>
);

export default Education;
