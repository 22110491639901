import Icon from '../components/Icon';
import Title from '../components/Title';

const Tech = ({children}: React.PropsWithChildren<{}>) => (
  <div>
    <Icon icon="balance-scale" /> <Title>Technologies</Title>: {children}
  </div>
);

export default Tech;
