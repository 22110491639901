import styled from 'styled-components';

const Wrapper = styled.section`
  border-top: 1px solid #e0dbdb;
  margin-top: 10px;
`;

const Title = styled.h3`
  text-decoration: underline;
  font-weight: 500;
`;

const Description = styled.div`
  > p {
    font-size: 0.9em;
  }
`;

const Detail = () => (
  <Wrapper>
    <Title>Experience</Title>
    <Description>
      <p>
        - Tech stack: Typescript, React, Vue.js, Node.js, GraphQL, Urql, Code
        Generator, Apollo, Next.js, Nuxt.js, Tailwind CSS, Redux, Mobx,
        React-Query, REST, tRPC, Hook Form, Nexus, ES6+, Sass, Less, CSS-in-JS,
        PostCSS, SPA, PWA, BEM, i18Next, Hook, Antd, MUI, ChakraIU, NextUI, etc.
      </p>
      <p>
        - Deployment: Docker, Rancher, Vercel, Surge, Netlify, Deno, Deta Space.
      </p>
      <p>
        - Other tools: Webpack, Gulp, Babel, ESLint, Prettier, Electron, Prisma,
        Strapi, telegram-bot, Firebase, Sentry, Postmain, CI/CD, Github Actions,
        GitlabCI, yarn, pnpm, open-source npm packages, etc.
      </p>
    </Description>
  </Wrapper>
);

export default Detail;
