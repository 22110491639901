import clsx from 'clsx';
import styled from 'styled-components';

const I = styled.i`
  color: #ad125b;
  font-size: 14px;
  width: 16px;
`;
interface Props {
  icon: string;
}

const Icon = ({icon}: Props) => <I className={clsx('fa', 'fa-' + icon)} />;

export default Icon;
