import styled from 'styled-components';

const Section = styled.section`
  color: #fff;
  padding: 10px;
`;

const Wrapper = ({children}: React.PropsWithChildren<{}>) => (
  <Section>{children}</Section>
);

export default Wrapper;
