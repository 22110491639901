import styled from 'styled-components';
import Icon from '../components/Icon';
import Title from '../components/Title';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const Demo = ({
  children,
  title,
}: React.PropsWithChildren<{
  title?: string;
}>) => (
  <Wrapper>
    <Icon icon="chrome" /> <Title>{title ?? 'Website'}</Title>: {children}
  </Wrapper>
);

export default Demo;
