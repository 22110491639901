import styled from 'styled-components';

import Processing from './components/Processing';

const Li = styled.li`
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Skill = styled.div`
  font-size: 0.9em;
  margin-bottom: 5px;
`;

type Props = React.PropsWithChildren<{
  width: number;
}>;

const Item = ({children, width}: Props) => (
  <Li>
    <Skill>{children}</Skill>
    <Processing width={width} />
  </Li>
);

export default Item;
