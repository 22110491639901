import styled from 'styled-components';

const Span = styled.span`
  text-decoration: underline;
`;

const Title = ({children}: React.PropsWithChildren<{}>) => (
  <Span>{children}</Span>
);

export default Title;
