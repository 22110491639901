import clsx from 'clsx';
import styled from 'styled-components';

const H2 = styled.h1`
  color: #263238;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0px;
  > span {
    font-size: 0.9em;
  }
`;

const I = styled.i`
  color: #fff;
  background: #263238;
  border-radius: 50%;
  font-size: .6em;
  width: 30px;
  height: 29px;
  position: relative;
  transform: translateY(3px);
  margin-right: 5px;
  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(0.9);
  }
}
`;

type Props = React.PropsWithChildren<{
  icon: string;
}>;

const Title = ({children, icon}: Props) => (
  <H2>
    <I className={clsx('fa', 'fa-' + icon)} />
    <span>{children}</span>
  </H2>
);

export default Title;
