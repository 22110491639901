import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Open Sans", sans-serif;
  }

  body {
    background: #525659;
    color: #575757;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
`;
