import styled from 'styled-components';

import Title from '../components/Title';

const Description = styled.p``;

const Summary = () => (
  <section>
    <Title icon="terminal">Summary</Title>
    <Description>
      Web developer with more than 8 years of experience coding technically
      sophisticated web applications using Javascript, <s>PHP</s>.
    </Description>
    <Description>
      Currently, I'm focused on building applications in Javascript / Typescript
      such as React, NodeJS, GraphQL, React Native and I'm passionate about
      sharing the things I learn with others.
    </Description>
  </section>
);

export default Summary;
