import styled from 'styled-components';

import Title from '../components/Title';
import Wrapper from '../components/Wrapper';

const Item = styled.p`
  font-size: 0.9em;
`;

const Interest = () => (
  <Wrapper>
    <Title>Interests</Title>
    <Item>Coffee code, hiking</Item>
  </Wrapper>
);

export default Interest;
