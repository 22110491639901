import styled from 'styled-components';

const H2 = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
`;

const Title = ({children}: React.PropsWithChildren<{}>) => <H2>{children}</H2>;

export default Title;
