import Title from '../components/Title';

const Advantage = () => (
  <section>
    <Title icon="coffee">Advantages &amp; Disadvantages</Title>
    <p>
      - Working in group, sense of responsibility and high ability to work
      independently.
    </p>
    <p>- Passion, quick learner and ability to learn new things.</p>
    <p>- Love learning new technology.</p>
    <p>
      - I am working to improve my soft skills such as communication skills by
      taking part in training courses and workshops.
    </p>
  </section>
);

export default Advantage;
