import Title from '../components/Title';
import Item from '../components/Item';
import A from '../components/A';
import Demo from './components/Demo';
import Description from './components/Description';
import Tech from './components/Tech';

const Project = () => (
  <section>
    <Title icon="umbrella">Featured Projects</Title>

    <Item title="12BAY.VN (Co-creator)">
      <Demo>
        <A href="https://12bay.vn">12BAY.VN</A>
      </Demo>
      <Description>
        12BAY.VN is an all-in-one travel booking app that lets you find and book
        flights, buses and trains with perks like price-drop alerts, reward
        points, and redemption offers.
      </Description>
      <Tech>
        <p>
          - Web application: Next.js, GraphQL, ChakraUI, NextUI, Tailwind CSS,
          Firebase, PWA, etc
        </p>
      </Tech>
    </Item>

    <Item title="Flash Sales Boosting Platform">
      <Demo>
        The project is a microservice integrated into the{' '}
        <A href="https://sellercenter.tiki.vn">Tiki SellerCenter</A> platform.
        More <A href="/tiki-boosting.png">SCREENSHOT-1.PNG</A>
      </Demo>
      <Description>
        Build a self-served service for seller and brand can go and set the bid
        and budget to promote the flash deal product.
      </Description>
      <Tech>
        <p>
          - Web application: React(Typescript),{' '}
          <A href="https://trpc.io">tRPC</A>, Antd, Tailwind CSS
        </p>
      </Tech>
    </Item>

    <Item title="Data Governance Platform">
      <Demo title="Screenshot">
        <A href="/tiki-july.png">SCREENSHOT-1.PNG</A>. This is an internal
        project of Tiki.
      </Demo>
      <Description>
        Data Governance Platform is a platform that has the following key
        features:
        <p>
          - Databook: be able to look up everything related to “data assets” in
          Tiki Data
        </p>
        <p>
          - Airflow Wrapper: be able to create ETL flow (DAG in Airflow) easily,
          help users to manage permission (share to other users) & revision
          history
        </p>
        <p>
          - Resource Management: monitor & control resources/permissions on GCP,
          help users to request resources/permissions easily
        </p>
        <p>
          - Streaming Configuration: be able to config streaming from Kafka to
          BQ.
        </p>
      </Description>
      <Tech>
        <p>
          - Web application: Next.js(Typescript), React-query, MantineUI,
          Tailwind
        </p>
      </Tech>
    </Item>

    <Item title="A/B Testing Platform">
      <Demo title="Screenshot">
        <A href="/tiki-spectrum.png">SCREENSHOT-1.PNG</A>. This is an internal
        project of Tiki.
      </Demo>
      <Description>
        At Tiki, we have built our own A/B testing system that helps test and
        compare different versions of a webpage and app to determine which
        version performs better.
      </Description>
      <Tech>
        <p>
          - Web application: Next.js(Typescript), React-query, MUI, Tailwind CSS
        </p>
      </Tech>
    </Item>

    <Item title="Fraud Detecting System">
      <Demo title="Screenshot">
        <A href="/tiki-fraud.png">SCREENSHOT-1.PNG</A>. This is an internal
        project of Tiki.
      </Demo>
      <Description>
        Tiki, like many other e-commerce platforms, likely has a fraud detecting
        system in place to help prevent fraudulent activities on their platform.
      </Description>
      <Tech>
        <p>- Web application: Next.js, GraphQL, Antd</p>
      </Tech>
    </Item>

    <Item title="PowerSell Desktop">
      <Demo>
        <A href="https://powersell.com/vi/powerchat">PowerSell Desktop</A>
      </Demo>
      <Description>
        PowerSell Desktop is a desktop application another version of PowerSell
        with more advance features.
      </Description>
      <Tech>
        <p>
          <A href="https://electronjs.org/">Electron.js</A>, React, Mobx
        </p>
      </Tech>
    </Item>

    <Item title="Fondo.Exchange (Terminated)">
      <Demo title="Screenshot">
        <A href="/fondo.png">SCREENSHOT-1.PNG</A>. This project is still in
        progress, so it's too early to publicize it on the internet.
      </Demo>
      <Description>
        The Cryptocurrency Trading Platform. Fondo Network is a UK-based
        Blockchain Trading Exchange
      </Description>
      <Tech>
        <p>
          - Backend: Rails, postgres, redis, rabbitmq, slanger instead of pusher
        </p>
        <p>
          - Web application: Vue.js. Build application based on{' '}
          <A href="https://nuxtjs.org">Nuxt.js</A>
        </p>
        <p>- Deployment: Docker</p>
      </Tech>
    </Item>

    <Item title="RACE.SE">
      <Demo>
        <A href="http://race.se">RACE.SE</A>
      </Demo>
      <Description>
        Race.se gives you all races in Sweden Everything bundled on mobile and
        PC, 24/7, all year round.
      </Description>
      <Tech>
        <p>- Backend: API by Magento</p>
        <p>- The version current: React and Backbone</p>
        <p>
          - The beta version will release soon: React, Next.js, Redux,
          Redux-Saga, Redux-Form, Styled-Components, PWA
        </p>
        <p>- Deployment: Docker</p>
      </Tech>
    </Item>

    <Item title="List of Projects at the Toyota Brand in Vietnam">
      <Demo>
        <A href="http://toyotamydinh.com.vn">Toyota My Dinh</A>,{' '}
        <A href="http://toyotabacninh.com">Toyota Bac Ninh</A>,{' '}
        <A href="http://toyotathanhxuan.com.vn">Toyota Thanh Xuan</A>,{' '}
        <A href="http://toyotamydinh-caudien.vn">Toyota Cau Dien</A>
      </Demo>
      <Description>
        Toyota is generally the world's leading car brand. This is one of their
        products in Vietnam.
      </Description>
      <Tech>
        <p>
          - Summary: SPA support server-side-rendering(
          <A href="https://goo.gl/4PjkfU">Universal</A>) a good SEO
        </p>
        <p>- API: Restful by Laravel + Dingo / MySQL</p>
        <p>- Web application: Express, Vue.js, Vuex, Router</p>
        <p>- Deployment: Docker</p>
      </Tech>
    </Item>

    <Item title="Toy Projects">
      <div>
        <A href="https://notes.nghiep.dev">Notes</A> - The simplest way to keep
        text notes
      </div>
      <div>
        <A href="https://arent.nghiep.dev">Healthy</A> - A web application for
        health care
      </div>
    </Item>
  </section>
);

export default Project;
